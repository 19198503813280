import { Box, Heading, Text, Badge, useDisclosure } from '@chakra-ui/react';

import UserHistoryCard from './UserHistoryCard';
import HistoryDrawer from '../HistoryDrawer/HistoryDrawer';

export default function UserHistory() {
  // Abre el drawer de notifications
  const {
    isOpen: isOpenHistoryDrawer,
    onOpen: onOpenHistoryDrawer,
    onClose: onCloseHistoryDrawer,
  } = useDisclosure();

  return (
    <Box maxWidth="700px" margin="0 auto">
      <Box mt={5}>
        <Heading fontSize="24px" fontFamily="DM Sans">
          Historial{' '}
          <Badge ml={1} bg="#EBD0FF">
            NUEVO
          </Badge>
        </Heading>
        <Text fontStyle="italic" opacity="0.8" fontSize="12px">
          Revisa todos los retos que has completado
        </Text>
      </Box>
      <Box display="flex" mt={5} overflowX="auto" pb={7}>
        <UserHistoryCard onOpenHistoryDrawer={onOpenHistoryDrawer} />
        <HistoryDrawer
          isOpenHistoryDrawer={isOpenHistoryDrawer}
          onCloseHistoryDrawer={onCloseHistoryDrawer}
        />
      </Box>
    </Box>
  );
}
