import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import './ChallengeItemModalDescription.css';

export default function ChallengeItemModalDescription({ isMission, description, challenge }) {
  return (
    <Box p="0px 20px 20px 20px">
      <Text fontFamily="DM Sans" fontSize="20px" fontWeight="bold" mb={2}>
        {challenge.brand.name.toUpperCase()} {challenge?.subtitle}
      </Text>
      <Text fontFamily="DM Sans" mb={2} fontWeight="bold">
        Descripción {isMission ? 'de la misión' : 'del reto'}
      </Text>
      <div className="description-html">
        <Box
          fontFamily="DM Sans"
          fontSize="14px"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </Box>
  );
}
