/* eslint-disable no-console */
/* eslint-disable no-prototype-builtins */

import React, { useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Box, useToast } from '@chakra-ui/react';

import LoadingChallenges from '../LoadingChallenges/LoadingChallenges';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import Header from '../Header/Header';
import UserInfo from '../UserInfo/UserInfo';
import UserBalance from '../UserBalance/UserBalance';
import PaymentMethods from '../PaymentMethods/PaymentMethods';
import UserHistory from '../UserHistory/UserHistory';
import UserLinks from '../UserLinks/UserLinks';
import UGCSection from '../UGCSection/UGCSection';
import StartEarning from '../StartEarning/StartEarning';
import AmbassadorForm from '../AmbassadorForm/AmbassadorForm';
import UserData from '../UserData/UserData';
import FooterNav from '../FooterNav/FooterNav';

import { useGetUserDataQuery } from '../../Redux/features/auth/authApiSlice';

const POLLING_INTERVAL = 30000;

export default function Profile() {
  // Toast
  const toast = useToast();
  const showToast = useCallback(
    (message, type) => {
      toast({
        title: `${message}`,
        status: type,
        isClosable: true,
        duration: 2000,
      });
    },
    [toast],
  );

  // Cuando conecta el Paypal en el perfil
  const [searchParams] = useSearchParams();
  const payPalConnectedSuccess = searchParams.get('paypalConnected');

  // Pedimos los datos del user
  const { data: user, isLoading: isUserLoading } = useGetUserDataQuery(null, {
    pollingInterval: POLLING_INTERVAL,
  });

  // Si pertenece o no a un afiliado
  const hasAmbassadorUser = user?.ambassador.hasOwnProperty('user');

  // Si ya tiene o no un challenge aprobado o reclamado
  const hasApprovedChallenge = user?.challenges.some(
    (userChallenge) => userChallenge.status === 'approved' || userChallenge.status === 'claimed',
  );

  // Vemos si el creador es UGC o no para mostrarle la nueva card para ir a su panel de UGC
  const isUGC = user?.isUGC;

  // Detectamos si ha conectado el tiktok y mostramos confetti
  useEffect(() => {
    if (payPalConnectedSuccess === 'true') {
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/js-confetti@latest/dist/js-confetti.browser.js';
      script.async = false;
      document.body.appendChild(script);
      let JSConfetti = null;
      script.onload = () => {
        if (window.JSConfetti) {
          JSConfetti = window.JSConfetti;
          const jsConfetti = new JSConfetti();
          jsConfetti.addConfetti({
            emojiSize: 100,
            confettiNumber: 100,
          });
        }
      };
      showToast('PayPal conectado correctamente', 'success');
    } else if (payPalConnectedSuccess === 'false') {
      showToast('Error al conectar con PayPal, inténtalo de nuevo', 'error');
    }
  }, [payPalConnectedSuccess, showToast]);

  if (isUserLoading) {
    return <LoadingChallenges />;
  }

  return (
    <Box bg="#FFFFF2">
      <ScrollToTop />
      <Header user={user} />
      <Box pb={10} m={5}>
        <UserInfo userData={user} />
        <UserBalance userData={user} />
        <PaymentMethods userData={user} />
        {isUGC && <UGCSection />}
        <UserHistory />
        <UserLinks />
        <StartEarning userData={user} />
        {!hasAmbassadorUser && !hasApprovedChallenge && <AmbassadorForm userData={user} />}
        <UserData userData={user} />
      </Box>
      <FooterNav />
    </Box>
  );
}
