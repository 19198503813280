/* eslint-disable no-console */

import React from 'react';

import {
  Box,
  Flex,
  Heading,
  Button,
  Text,
  Spacer,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useToast,
} from '@chakra-ui/react';

import calculateChallengeDifficulty from '../../Utils/calculateChallengeDifficulty';

import {
  useApproveChallengeMutation,
  useDeclineChallengeMutation,
} from '../../Redux/features/challenges/challengesApiSlice';

export default function AdminChallengeItem({ userId, email, challengeData, videoData }) {
  const { _id, brand, description, hashtags, mentions, reward } = challengeData;

  const toast = useToast();

  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
    });
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [approve, { isLoadingApprove }] = useApproveChallengeMutation();
  const [decline, { isLoadingDecline }] = useDeclineChallengeMutation();

  const approveChallenge = async () => {
    try {
      await approve({ userId, challengeId: _id }).unwrap();
      showToast('Reto aprobado con éxito', 'success');
    } catch (error) {
      showToast('Hubo un problema aprobando el reto', 'error');
      console.log(error);
    }
  };

  const declineChallenge = async () => {
    try {
      await decline({ userId, challengeId: _id }).unwrap();
      showToast('Reto rechazado con éxito', 'success');
    } catch (error) {
      showToast('Hubo un problema rechazando el reto', 'error');
      console.log(error);
    }
  };

  return (
    <>
      <Box
        fontFamily="DM Sans"
        bg="#fff"
        width="100%"
        borderStyle="dashed"
        borderColor="#000000"
        borderWidth="1px"
        borderRadius={4}
        mb={4}
      >
        <Flex>
          <Box p={2}>
            <Box
              width="50px"
              borderRadius="50%"
              height="50px"
              backgroundPosition="center"
              backgroundSize="contain"
              backgroundRepeat="no-repeat"
              backgroundImage={brand.logoUrl}
            />
          </Box>
          <Box p={2}>
            <Flex mb={1} alignItems="center">
              <Heading fontSize="16px">{brand.name}</Heading>
              <Spacer />
              <Box
                onClick={onOpen}
                fontSize="12px"
                px={1}
                bg="#FFFFF2"
                border="1px solid #eee9e9"
                borderRadius={4}
              >
                {calculateChallengeDifficulty(reward)}
              </Box>
            </Flex>
            <Text fontSize="12px" lineHeight={1.3}>
              Usuario: {email}
            </Text>
            <Text fontSize="12px" lineHeight={1.3}>
              {description}
            </Text>
            <Box mt={2}>
              <Text opacity={0.8} fontSize="12px">
                Incluye:
                {mentions.map((m) => `@${m}`)} {hashtags.map((h) => `#${h} `)}
              </Text>
            </Box>
            <Flex mt={3} mb={1} pr={1}>
              <Button
                textDecoration="underline"
                fontWeight="medium"
                variant="unstyled"
                size="xs"
                onClick={() => window.open(videoData.shareUrl, '_blank')}
              >
                Ver video
              </Button>
              <Spacer />
              <Button
                isLoading={isLoadingDecline}
                onClick={() => declineChallenge()}
                _hover={{ bg: '#000000' }}
                paddingRight="12px"
                paddingLeft="12px"
                marginRight="12px"
                bg="#000000"
                color="white"
                variant="solid"
                size="xs"
              >
                Rechazar
              </Button>
              <Button
                isLoading={isLoadingApprove}
                onClick={() => approveChallenge()}
                _hover={{ bg: '#000000' }}
                paddingRight="12px"
                paddingLeft="12px"
                bg="#000000"
                color="white"
                variant="solid"
                size="xs"
              >
                Aprobar
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader fontSize="16px" fontFamily="DM Sans" borderBottomWidth="1px">
            Recompensas reto:
            {brand.name} {calculateChallengeDifficulty(reward)}
          </DrawerHeader>
          <DrawerBody>
            <Flex mt={5} justifyContent="space-between">
              <Text borderRadius={4} px={2} py={1} fontSize="16px" border="1px solid #eee9e9">
                Mínima*:
                <b>{reward.fixed}€</b>
              </Text>
              <Text borderRadius={4} px={2} py={1} fontSize="16px" border="1px solid #eee9e9">
                +
              </Text>
              <Text borderRadius={4} px={2} py={1} fontSize="16px" border="1px solid #eee9e9">
                Variable*:
                <b>{reward.variable}€</b>
                /1000 views
              </Text>
            </Flex>
            <Flex mt={5} mb={5} flexDirection="column">
              <Text lineHeight={1.2} fontSize="12px">
                Mínima*: ganarás esta cantidad tras subir el vídeo y ser aprobado por la marca
              </Text>
              <Text lineHeight={1.2} mt={2} fontSize="12px">
                Variable*: ganarás por las views conseguidas en las primeras 48h del vídeo
              </Text>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
