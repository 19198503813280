import React, { useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../Redux/features/auth/authApiSlice';
import { setCredentials } from '../../Redux/features/auth/authSlice';

export default function AdminLoginPage() {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [login] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toast = useToast();

  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
    });
  };

  const handleSubmit = async () => {
    try {
      if (email !== 'hello@cruwi.com') {
        showToast('Hubo un problema con el login', 'error');
        return;
      }
      const { token } = await login({ email, password }).unwrap();
      dispatch(setCredentials({ user: email, token }));
      navigate('/admin/challenges');
    } catch (error) {
      showToast('Hubo un problema con el login', 'error');
    }
  };

  const handleClick = () => setShow(!show);
  return (
    <Box py={{ base: 5, sm: 20, lg: 32 }}>
      <Stack
        bg="gray.50"
        rounded="xl"
        p={{ base: 4, sm: 6, md: 8 }}
        spacing={{ base: 8 }}
        maxW={{ lg: 'lg' }}
        m={{
          base: 4,
          sm: 6,
          md: 8,
          lg: '0 auto',
        }}
      >
        <Stack spacing={4}>
          <Heading
            textAlign="center"
            color="gray.800"
            lineHeight={1.1}
            fontFamily="DM Sans"
            fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
          >
            Inicia sesión
          </Heading>
        </Stack>
        <Box as="form" mt={10}>
          <Stack spacing={4}>
            <Input
              placeholder="Email"
              fontFamily="DM Sans"
              bg="gray.100"
              border={0}
              olor="gray.900"
              _placeholder={{
                color: 'gray.500',
              }}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <InputGroup>
              <Input
                pr="4.5rem"
                fontFamily="DM Sans"
                bg="gray.100"
                border={0}
                color="gray.900"
                type={show ? 'text' : 'password'}
                placeholder="Contraseña"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <InputRightElement width="4.5rem" mr="10px">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? 'Ocultar' : 'Mostrar'}
                </Button>
              </InputRightElement>
            </InputGroup>
          </Stack>
          <Button
            fontFamily="DM Sans"
            mt={8}
            bg="#000000"
            w="full"
            color="white"
            _hover={{
              bg: '#000000',
            }}
            onClick={handleSubmit}
          >
            Entrar
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
