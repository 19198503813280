/* eslint-disable no-console */

import { useNavigate } from 'react-router-dom';

import { Box, Flex, Text } from '@chakra-ui/react';

import { GoChevronLeft } from 'react-icons/go';
import { FaUnlink } from 'react-icons/fa';

import { useGetUserDataQuery } from '../Redux/features/auth/authApiSlice';
import { useGetAffiliationsQuery } from '../Redux/features/affiliations/affiliationsApiSlice';
import { useGetChallengesQuery } from '../Redux/features/challenges/challengesApiSlice';

import LoadingChallenges from '../Components/LoadingChallenges/LoadingChallenges';
import ScrollToTop from '../Components/ScrollToTop/ScrollToTop';

import LinkItem from '../Components/LinkItem/LinkItem';

const POLLING_INTERVAL = 50000;

const findMatches = (affiliations, challenges) => {
  const matchedChallenges = [];

  // Check if affiliations and challenges are truthy before proceeding
  if (affiliations && challenges && affiliations.data && challenges.data) {
    affiliations.data.forEach((affiliation) => {
      const matchedChallenge = challenges.data.find(
        (challenge) => challenge._id === affiliation.challenge,
      );
      if (matchedChallenge) {
        matchedChallenges.push({
          affiliation,
          challenge: matchedChallenge,
        });
      }
    });
  }

  return matchedChallenges;
};

// Consejos para los creadores
const advices = [
  {
    id: 1,
    title: 'Pon tu link en tu bio de TikTok',
    content:
      'Comparte tu link en tu bio de TikTok. Así cuando la gente vea tu vídeo del reto podrá ir ahí. Es importante que los dirijas desde el vídeo. Comentando que tienes ahí el link.',
  },
  {
    id: 2,
    title: 'Comparte tu link en tus otras RRSS',
    content:
      'Comparte tu link con tu gente o tu comunidad de Instagram. Si has creado el vídeo del reto, descárgatelo y subirlo como historia. Así explicarás a tu comunidad de Instagram en qué consiste la app donde se van a registrar',
  },
  {
    id: 3,
    title: 'Sube más vídeos a TikTok',
    content:
      'Aprovecha que tendrás en el link en tu bio de TikTok para subir más vídeos que dirijan a el. Utiliza los trends virales del momento y adáptalos a la marca del link.',
  },
  {
    id: 4,
    title: 'Compártelo por WhatsApp',
    content:
      'Si crees que a tus amigos y familia les puede ser útil lo que estás promocionando, puedes compartir tu link por chat individuales o por grupos.',
  },
  {
    id: 5,
    title: '¿Promocionar tu vídeo con Ads?',
    content:
      'En algunos casos el variable por conversiones es muy atractivo. Echa cuentas y prueba a potenciar tu vídeo con ads. Además, podrás vincular tu link al anuncio.',
  },
];

export default function AffiliationsPage() {
  // Para mandarlo atrás
  const navigate = useNavigate();

  // Pedimos datos del usuario
  const { data: user, isLoading: isUserLoading } = useGetUserDataQuery(null, {
    pollingInterval: POLLING_INTERVAL,
  });

  // Pedimos los affiliations del usuario
  const { data: affiliations, isLoading: isLoadingAffiliations } = useGetAffiliationsQuery();

  // Pedimos todos los retos
  const { data: challenges, isLoading: isLoadingChallenges } = useGetChallengesQuery({
    status: null,
  });

  // Esperamos que todo cargue
  if (isUserLoading || isLoadingAffiliations || isLoadingChallenges) {
    return <LoadingChallenges />;
  }

  // Filtramos de los retos, el que tenga afiliados del usuario
  const userChallengesWithAffiliation = findMatches(affiliations || [], challenges || []);

  return (
    <Box fontFamily="DM Sans" minH="100vh" bg="rgb(221, 222, 209)">
      <ScrollToTop />
      <Box pb={10} p={5}>
        <Box mb={8} maxWidth="700px" margin="0 auto">
          <Flex
            onClick={() => navigate(-1)}
            alignItems="center"
            mb={2}
            _hover={{ cursor: 'pointer' }}
          >
            <GoChevronLeft size="14px" />
            <Text fontSize="14px">Atrás</Text>
          </Flex>
          <Box>
            <Text fontSize="24px" fontWeight="bold" mb="8px">
              Tus links de referidos
            </Text>
            <Text fontSize="12px" lineHeight={1.3}>
              Aquí podrás hacer seguimiento de todos tus links de referidos. Cada vez que participes
              en un reto que contenga ‘recompensa por conversiones’, añadiremos un nuevo link aquí.
            </Text>
          </Box>
        </Box>

        {/* Links */}
        <Flex
          flexDir={['column', 'column', 'column', 'column', 'column']}
          maxWidth="700px"
          margin="20px auto 0px auto"
        >
          {userChallengesWithAffiliation &&
            userChallengesWithAffiliation.length > 0 &&
            userChallengesWithAffiliation.map((userAffiliation) => (
              <LinkItem
                user={user}
                key={userAffiliation.affiliation._id}
                challenge={userAffiliation.challenge}
                affiliation={userAffiliation.affiliation}
              />
            ))}

          {/* Si no hay, mostramos mensaje */}
          {userChallengesWithAffiliation && userChallengesWithAffiliation.length === 0 && (
            <Flex
              flexDir="column"
              justifyContent="center"
              alignItems="center"
              maxW="200px"
              m="20px auto 0 auto"
            >
              <Box mb={2}>
                <FaUnlink />
              </Box>
              <Text textAlign="center" lineHeight={1.3} fontSize="12px">
                No participas en ningún reto con recompensas de conversión
              </Text>
            </Flex>
          )}

          {/* Consejos de compartir los enlaces */}
          <Box id="share-advices" mt={10}>
            <Text fontSize="24px" fontWeight="bold" mb="8px" lineHeight={1.3}>
              Consejos para que más gente utilice tu link
            </Text>
            <Text fontSize="12px" mb={5}>
              Multiplica las posibilidades de que más gente utilice tu link para poder conseguir más
              recompensas. Además de publicar el vídeo del reto en TikTok, puedes compartir tu link
              por más canales.
            </Text>
            <Box>
              {advices.map((adv) => (
                <Flex
                  key={adv.title}
                  textAlign="center"
                  justifyContent="center"
                  flexDir="column"
                  border="1px solid"
                  p={3}
                  mb={4}
                  borderRadius="24px"
                >
                  <Box
                    fontSize="12px"
                    textAlign="center"
                    border="1px solid"
                    borderRadius="16px"
                    width="80px"
                    margin="0 auto 10px auto"
                  >
                    Consejo {adv.id}
                  </Box>
                  <Text mb={1} fontSize="16px" fontWeight="bold">
                    {adv.title}
                  </Text>
                  <Text fontSize="12px">{adv.content}</Text>
                </Flex>
              ))}
            </Box>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
