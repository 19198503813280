import apiSlice from '../../api/apiSlice';

export const challengesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChallenges: builder.query({
      query: ({ status, isMission }) => {
        const params = new URLSearchParams();
        if (status) {
          params.append('isActive', status);
        }
        if (isMission) {
          params.append('isMission', true);
        } else {
          params.append('isMission', false);
        }
        return `challenges?${params.toString()}`;
      },
      providesTags: ['Challenges'],
    }),
    participateInChallenge: builder.mutation({
      query: (challengeId) => ({
        url: `challenges/${challengeId}/activate`,
        method: 'POST',
      }),
      invalidatesTags: ['UserData', 'Challenges', 'Affiliations'],
    }),
    claim: builder.mutation({
      query: (challengeId) => ({
        url: `challenges/${challengeId}/claim`,
        method: 'POST',
      }),
      invalidatesTags: ['UserData', 'Challenges'],
    }),
    notifyMe: builder.mutation({
      query: (challengeId) => ({
        url: `challenges/${challengeId}/subscribe`,
        method: 'POST',
      }),
      invalidatesTags: ['UserData', 'Challenges'],
    }),
    claimReferral: builder.mutation({
      query: (referralId) => ({
        url: `user/referrals/${referralId}/claim`,
        method: 'POST',
      }),
      invalidatesTags: ['UserData'],
    }),
    approveChallenge: builder.mutation({
      query: ({ userId, challengeId }) => ({
        url: `user/${userId}/challenges/${challengeId}/approve`,
        method: 'POST',
      }),
      invalidatesTags: ['UserData', 'Challenges'],
    }),
    declineChallenge: builder.mutation({
      query: ({ userId, challengeId }) => ({
        url: `user/${userId}/challenges/${challengeId}/decline`,
        method: 'POST',
      }),
      invalidatesTags: ['UserData', 'Challenges'],
    }),
    stopParticipatingInChallenge: builder.mutation({
      query: (challengeId) => ({
        url: `challenges/${challengeId}/deactivate`,
        method: 'POST',
      }),
      invalidatesTags: ['UserData', 'Challenges', 'Affiliations'],
    }),
    repeatChallenge: builder.mutation({
      query: (challengeId) => ({
        url: `user-challenges/${challengeId}/repeat`,
        method: 'POST',
      }),
      invalidatesTags: ['UserData', 'Challenges', 'Affiliations'],
    }),
    getUserChallenges: builder.query({
      query: ({ status, downloaded, brandId }) => {
        const params = new URLSearchParams();
        if (status) {
          params.append('status', status);
        }
        if (downloaded) {
          params.append('downloaded', downloaded);
        }
        if (brandId) {
          params.append('brandId', brandId);
        }
        return `user-challenges?${params.toString()}`;
      },
      providesTags: ['UserChallenges'],
    }),
    uploadChallengeVideo: builder.mutation({
      query: ({ userId, challengeId, url }) => ({
        url: `user/${userId}/challenges/${challengeId}/upload-video`,
        method: 'POST',
        body: { url },
      }),
      invalidatesTags: ['UserData', 'UserChallenges'],
    }),
  }),
});

export const {
  useGetChallengesQuery,
  useParticipateInChallengeMutation,
  useClaimMutation,
  useNotifyMeMutation,
  useClaimReferralMutation,
  useApproveChallengeMutation,
  useUploadChallengeVideoMutation,
  useDeclineChallengeMutation,
  useGetUserChallengesQuery,
  useStopParticipatingInChallengeMutation,
  useRepeatChallengeMutation,
} = challengesApiSlice;
