/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { Box, Text, Flex, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { useGetUserDataQuery } from '../Redux/features/auth/authApiSlice';

import CustomLink from '../Components/CustomLink/CustomLink';
import LoadingBriefings from '../Components/LoadingBriefings/LoadingBriefings';

export default function ProtectedRouteUGC({ children }) {
  const navigate = useNavigate();
  const { data: user, isLoading: isUserLoading, isError } = useGetUserDataQuery();

  // Redirect to onboarding if the user has not passed onboarding
  useEffect(() => {
    if (!isUserLoading && user && !user.hasPassedUGCOnboarding) {
      navigate('/ugc/onboarding');
    }
  }, [user, navigate, isUserLoading]);

  // Show loading screen while user data is loading
  if (isUserLoading) {
    return <LoadingBriefings />;
  }

  // Handle potential error in fetching user data
  if (isError) {
    // You can replace this with a more sophisticated error handling component
    return <div>Error loading user data. Please try again later.</div>;
  }

  // Handle case where user is not a UGC creator
  if (!user?.isUGC) {
    return (
      <Flex fontFamily="DM Sans" alignItems="center" height="100vh" bg="#E4E4D9">
        <Box m={10}>
          <Text mb={2} fontSize="14px" fontWeight="black">
            Vaya, esto aún no está disponible para ti.
          </Text>
          <Text mb={4} fontSize="12px">
            Si crees que deberías ser creador UGC, envíanos un email a <u>hello@cruwi.com</u> con 2
            vídeos UGC tuyos y el link a tu TikTok
          </Text>
          <CustomLink to="/">
            <Button
              _hover={{ bg: '#000000' }}
              paddingRight="12px"
              paddingLeft="12px"
              bg="#000000"
              color="white"
              variant="solid"
              size="xs"
            >
              Volver a los retos
            </Button>
          </CustomLink>
        </Box>
      </Flex>
    );
  }

  // If user is a UGC creator and has passed onboarding, render the children components
  return children;
}
