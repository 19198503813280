import React, { useState } from 'react';
import { Input } from '@chakra-ui/react';
import { useGetUserChallengesQuery } from '../../Redux/features/challenges/challengesApiSlice';
import DownloadChallengeItem from '../../Components/ChallengeItem/DownloadChallengeItem';

export default function AdminDownloadPage() {
  const [brandId, setBrandId] = useState(undefined);

  const { data: challengesResponse } = useGetUserChallengesQuery({
    status: 'claimed',
    downloaded: 'false',
    brandId,
  });

  const userChallenges = challengesResponse?.data ?? [];

  return (
    <>
      <Input
        value={brandId}
        onChange={(e) => setBrandId(e.target.value)}
        placeholder="Brand ID"
        fontFamily="DM Sans"
        bg="gray.100"
        border={0}
        olor="gray.900"
        _placeholder={{
          color: 'gray.500',
        }}
      />
      {userChallenges.map((userChallenge) => (
        <DownloadChallengeItem
          userId={userChallenge._id}
          challengeData={userChallenge.challenge[0]}
          videoData={userChallenge.challenges.video}
          name={userChallenge.name}
          email={userChallenge.email}
          key={userChallenge.challenges._id}
        />
      ))}
    </>
  );
}
