import apiSlice from '../../api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (userData) => ({
        url: 'login',
        method: 'POST',
        body: userData,
      }),
      providesTags: ['UserData'],
    }),
    register: builder.mutation({
      query: (userData) => ({
        url: 'register',
        method: 'POST',
        body: userData,
      }),
      providesTags: ['UserData'],
    }),
    update: builder.mutation({
      query: (userData) => ({
        url: 'user',
        method: 'PATCH',
        body: userData,
      }),
      invalidatesTags: ['UserData'],
    }),
    delete: builder.mutation({
      query: (userData) => ({
        url: 'user',
        method: 'DELETE',
        body: userData,
      }),
      invalidatesTags: ['UserData'],
    }),
    getUserData: builder.query({
      query: () => 'user',
      providesTags: ['UserData'],
    }),
    sendToTikTokAuth: builder.query({
      query: () => 'tiktok/url',
      providesTags: ['UserData'],
    }),
    getPaypalAuthUrl: builder.query({
      query: () => 'paypal/url',
    }),
    withdrawBalance: builder.mutation({
      query: () => ({
        url: 'user/balance/withdraw',
        method: 'POST',
      }),
      providesTags: ['UserData'],
    }),
    requestChangePassword: builder.mutation({
      query: (userData) => ({
        url: 'restore-password',
        method: 'POST',
        body: userData,
      }),
    }),
    confirmNewPassword: builder.mutation({
      query: ({ token, password }) => ({
        url: `confirm-restore-password/${token}`,
        method: 'POST',
        body: { newPassword: password },
      }),
    }),
    uploadVideos: builder.mutation({
      query: ({ userId, videosData }) => ({
        url: `user/${userId}/ugc-videos`,
        method: 'POST',
        body: videosData,
      }),
      invalidatesTags: ['UserData'],
    }),
    deleteVideo: builder.mutation({
      query: ({ userId, videoId }) => ({
        url: `user/${userId}/ugc-videos/${videoId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserData'],
    }),
    updateVideo: builder.mutation({
      query: ({ userId, videoId, videoData }) => ({
        url: `user/${userId}/ugc-videos/${videoId}`,
        method: 'PATCH',
        body: videoData,
      }),
      invalidatesTags: ['UserData'],
    }),
    claimFirstChallengeReward: builder.mutation({
      query: () => ({
        url: 'user/rewards/first-challenge/claim',
        method: 'POST',
      }),
      invalidatesTags: ['UserData'],
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useGetUserDataQuery,
  useLazyGetPaypalAuthUrlQuery,
  useLazySendToTikTokAuthQuery,
  useUpdateMutation,
  useDeleteMutation,
  useWithdrawBalanceMutation,
  useRequestChangePasswordMutation,
  useConfirmNewPasswordMutation,
  useUploadVideosMutation,
  useDeleteVideoMutation,
  useUpdateVideoMutation,
  useClaimFirstChallengeRewardMutation,
} = authApiSlice;
