/* eslint-disable no-console */
/* eslint-disable no-prototype-builtins */

import React from 'react';

import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';

import { InfoOutlineIcon } from '@chakra-ui/icons';

import { FaTiktok } from 'react-icons/fa';

import { useLazySendToTikTokAuthQuery } from '../../Redux/features/auth/authApiSlice';

import ConnectTikTokButton from '../ConnectTikTokButton/ConnectTikTokButton';

const defaultProfileImage =
  'https://cruwi-creators.s3.eu-west-3.amazonaws.com/misc/default-profile.jpg';

export default function UserInfo({ userData }) {
  const { name, tikTok } = userData;

  const tikTokInfo = userData?.tikTokInfo;

  let profileImage;
  const hasTikTokConnected = userData?.tikTokInfo.hasOwnProperty('openId');
  if (hasTikTokConnected) {
    profileImage = userData?.tikTokInfo?.avatarUrl;
  } else {
    profileImage = defaultProfileImage;
  }

  const [sendToTikTok] = useLazySendToTikTokAuthQuery();
  const handleClickConnectTikTok = async () => {
    try {
      const tikTokApiResult = await sendToTikTok();
      if (tikTokApiResult.isSuccess) {
        window.location.replace(tikTokApiResult.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Cambio de tamaño para responsive
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  // Abre modal de info tiktok usuario
  const {
    isOpen: isOpenTikTokInfoModal,
    onOpen: onOpenTikTokInfoModal,
    onClose: onCloseTikTokInfoModal,
  } = useDisclosure();

  return (
    <Flex maxWidth="700px" margin="0 auto">
      <Box
        width="70px"
        height="70px"
        borderRadius="50%"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundImage={`url(${profileImage})`}
      />
      <Box p={2} marginLeft="10px">
        <Text fontSize="18px" fontWeight="bold">
          {name}
        </Text>
        <Flex alignItems="center">
          <FaTiktok fontSize="15px" />
          {hasTikTokConnected ? (
            <Box
              marginLeft={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={onOpenTikTokInfoModal}
            >
              {tikTok} <InfoOutlineIcon marginLeft="2px" mt="2px" height="12px" />
            </Box>
          ) : (
            <ConnectTikTokButton theme="white" click={handleClickConnectTikTok} />
          )}
        </Flex>
      </Box>

      {/* Modal tiktok conectado información */}
      <Modal
        size={isLargerThan768 ? 'lg' : 'lg'}
        isOpen={isOpenTikTokInfoModal}
        onClose={onCloseTikTokInfoModal}
        isCentered
        scrollBehavior={isLargerThan768 ? 'inside' : 'outside'}
      >
        <ModalOverlay />
        <ModalContent
          border="2px solid #EBD0FF"
          borderRadius="24px"
          background="#FFFFF2"
          m={5}
          maxHeight={isLargerThan768 ? '90%' : 'calc(100vh - 48px)'}
        >
          <ModalCloseButton />
          <ModalBody padding={0}>
            <Flex direction="column" p={5}>
              <Box flex="1" pt={5}>
                <Text mb={3} fontSize={22} fontFamily="DM Sans" fontWeight="bold" lineHeight={1.2}>
                  {name}, tienes conectado este TikTok
                </Text>

                <Box
                  fontFamily="DM Sans"
                  bg="#FFF"
                  width="100%"
                  mb={4}
                  textAlign="center"
                  padding={3}
                  border="2px solid black"
                  borderRadius="16px"
                  boxShadow="2px 2px 0px 0px #000"
                >
                  <Box
                    width="70px"
                    height="70px"
                    borderRadius="50%"
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    backgroundPosition="center"
                    backgroundImage={`url(${profileImage})`}
                    margin="0 auto"
                  />
                  <Text
                    mt={2}
                    fontWeight="bold"
                    fontSize="16px"
                    fontFamily="DM Sans"
                    lineHeight={1.3}
                    mb={3}
                  >
                    {tikTok}
                  </Text>
                  <Flex justifyContent="center">
                    <Box mr={4}>
                      <Text lineHeight={1.1} fontSize="14px" fontWeight="bold">
                        {tikTokInfo.followingCount}
                      </Text>
                      <Text fontSize="14px">Siguiendo</Text>
                    </Box>
                    <Box mr={4}>
                      <Text lineHeight={1.1} fontSize="14px" fontWeight="bold">
                        {tikTokInfo.followerCount}
                      </Text>
                      <Text fontSize="14px">Seguidores</Text>
                    </Box>
                    <Box>
                      <Text lineHeight={1.1} fontSize="14px" fontWeight="bold">
                        {tikTokInfo.likesCount}
                      </Text>
                      <Text fontSize="14px">Me gusta</Text>
                    </Box>
                  </Flex>
                </Box>

                <Text fontSize="16px" fontFamily="DM Sans" lineHeight={1.3} mb={5}>
                  Recuerda subir los retos a esta cuenta de TikTok. Si lo subes a otra cuenta, no
                  podremos detectar tu vídeo y, por tanto, la marca no podrá revisarlo.
                </Text>

                <Text fontSize="16px" fontFamily="DM Sans" lineHeight={1.3} mb={5}>
                  Si necesitas cambiar de cuenta, no dudes en contactarnos por email a:{' '}
                  <u>hello@cruwi.com</u>
                </Text>
              </Box>

              <Box marginTop="50px">
                <Button
                  onClick={() => onCloseTikTokInfoModal()}
                  _hover={{ bg: '#000000' }}
                  fontFamily="DM Sans"
                  paddingRight="12px"
                  paddingLeft="12px"
                  border="2px solid black"
                  boxShadow="1px 1px 0px 0px #000"
                  borderRadius="16px"
                  bg="#FFFFF2"
                  color="black"
                  width="100%"
                  size="md"
                >
                  Cerrar
                </Button>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
