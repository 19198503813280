import React from 'react';
import { useGetUserChallengesQuery } from '../../Redux/features/challenges/challengesApiSlice';
import AdminChallengeItem from '../../Components/ChallengeItem/ChallengeAdminItem';

export default function AdminChallengesPage() {
  const { data: challengesResponse } = useGetUserChallengesQuery({ status: 'revision' });

  const userChallenges = challengesResponse?.data ?? [];

  return userChallenges.map((userChallenge) => (
    <AdminChallengeItem
      userId={userChallenge._id}
      challengeData={userChallenge.challenge[0]}
      videoData={userChallenge.challenges.video}
      name={userChallenge.name}
      email={userChallenge.email}
      key={userChallenge.challenge[0]._id}
    />
  ));
}
