/* eslint-disable no-console */

import React, { useState } from 'react';

import { Box, Button, Flex, Spacer, Text, Tooltip, useDisclosure } from '@chakra-ui/react';

import { BiLockAlt } from 'react-icons/bi';

import formatCentsToEurs from '../../Utils/formatCentsToEurs';

import KYCModal from '../KYCModal/KYCModal';

export default function UserBalance({ userData }) {
  const { balance, taxData } = userData;

  // Abre modal de datos fiscales
  const {
    isOpen: isOpenTaxModal,
    onOpen: onOpenTaxModal,
    onClose: onCloseTaxModal,
  } = useDisclosure();

  // Si ya tiene los datos, no mostramos la parte de revisión de los datos.
  // mostramos la 2º parte del modal sin esa parte
  const showEditData = Boolean(taxData?.dni);

  // Paso del form de datos fiscales
  const [step, setStep] = useState(2);

  // Texto de saldo
  const getBalanceText = (userBalance) => {
    let text = '';
    if (20 - userBalance / 100 < 0) {
      text = '¡Ya puedes reclamar tu saldo!';
    } else {
      text = `faltan ${20 - userBalance / 100}€ para reclamar tu saldo`;
    }
    return text;
  };

  // Comprueba si el usuario puede retirar saldo
  const isWithdrawable = balance >= 2000;

  // Acción de retirar saldo o abrir modal de datos fiscales si no está rellenos los datos
  const showRequiredStepTaxModal = () => {
    if (!taxData?.dni) {
      setStep(0);
      onOpenTaxModal();
    } else {
      setStep(1);
      onOpenTaxModal();
    }
  };

  return (
    <Box
      maxWidth="700px"
      margin="0 auto"
      p={3}
      bg="#fff"
      border="2px solid black"
      borderRadius="16px"
      boxShadow="2px 2px 0px 0px #000"
      mt={5}
      width="100%"
    >
      <Box>
        <Flex alignItems="flex-end">
          <Text fontFamily="DM Sans" fontWeight="bold" fontSize="16px">
            Saldo actual
          </Text>
          <Text
            fontFamily="DM Sans"
            marginLeft="5px"
            lineHeight={1.7}
            fontSize="12px"
            opacity={0.85}
          >
            ({getBalanceText(balance)})
          </Text>
        </Flex>
        <Flex textAlign="center" fontFamily="DM Sans" flexDirection="column">
          <Text fontFamily="DM Sans" fontWeight="extrabold" fontSize="50px">
            {formatCentsToEurs(balance)}
          </Text>
        </Flex>
      </Box>
      <Flex alignItems="center">
        <Text fontFamily="DM Sans" fontSize="12px" opacity={0.85}>
          Gastos de gestión por retirada: 20%
        </Text>
        <Spacer />
        {isWithdrawable ? (
          <Button
            className="button-pulse"
            _hover={{ bg: '#000000' }}
            paddingRight="12px"
            paddingLeft="12px"
            bg="#000000"
            color="white"
            variant="solid"
            size="xs"
            onClick={() => showRequiredStepTaxModal()}
          >
            Retirar {formatCentsToEurs(balance)}
          </Button>
        ) : (
          <Tooltip label="Debes llegar a 20€">
            <Button disabled="true" variant="solid" size="xs">
              Retirar &nbsp; <BiLockAlt />
            </Button>
          </Tooltip>
        )}
      </Flex>

      <KYCModal
        step={step}
        showEditData={showEditData}
        setStep={setStep}
        isOpen={isOpenTaxModal}
        close={onCloseTaxModal}
        balance={balance}
      />
    </Box>
  );
}
